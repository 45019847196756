import Roles from '@/users/models/roles'
import authService from '@/users/services/auth-service'

export default class Permissions {

  get authenticated() {
    return authService.authenticated()
  }

  get isAdmin() {
    return this.isInRole(Roles.Admin)
  }

  get isTenant() {
    return this.isInRole(Roles.Tenant)
  }

  get isDriver() {
    return this.isInRole(Roles.Driver)
  }

  public isInRole(role: string) {
    return authService.isInRole(role)
  }

  public isInAnyRole(roles: string[]) {
    return authService.isInAnyRole(roles)
  }
}
