import axios from '@/app/axios'
import bus from '@/app/bus'
import routing from '@/app/config/routing'
import router from '@/app/router'
import store from '@/app/store'
import Responses from '@/app/utils/responses'
import AuthRequest from '@/users/requests/auth-request'
import AuthResponse from '@/users/responses/auth-response'
import AuthModule from '@/users/store/auth-module'
import { getModule } from 'vuex-module-decorators'

class AuthService {

  public async loginAsync(request: AuthRequest) {
    const authModule = getModule(AuthModule, store)
    const res = await axios.post<AuthResponse>('auth/login', request)
    const response = Responses.obj(res)
    if (response != null) {
      // Data has been loaded.
      response.loaded = true
      if (response.authenticated && response.token !== null) {
        authModule.login(response)
        bus.$emit('auth.logged-in')
        this.redirect()
      }
      return response
    }
    return null
  }

  public async impersonate(request: AuthRequest) {
    const authModule = getModule(AuthModule, store)
    const res = await axios.post<AuthResponse>('auth/impersonate', request)
    const response = Responses.obj(res)
    if (response != null) {
      // Data has been loaded.
      response.loaded = true
      if (response.authenticated && response.token !== null) {
        authModule.login(response)
        bus.$emit('auth.logged-in')
        this.redirect()
      }
      return response
    }
    return null
  }

  public async resetPasswordAsync(email: string, token: string, password: string) {
    const res = await axios.post('auth/resetPassword', {
      email: email,
      token: token,
      password: password,
    })
    return [res.data.success, res.data.message]
  }

  public async sendPasswordResetLinkAsync(email: string) {
    const res = await axios.post('auth/sendPasswordResetLink', { email: email })
  }

  public async changePasswordAsync(password: string, passwordConfirmation: string) {
    const res = await axios.post('auth/changePassword', {
      password: password,
      passwordConfirmation: passwordConfirmation,
    })
    return [res.data.success, res.data.message]
  }

  public async logoutAsync() {
    const authModule = getModule(AuthModule, store)
    await axios.post('auth/logout')
    authModule.logout()
    // Browser History cannot be flushed.
    router.push(routing.logoutRedirect)
  }

  public authenticated() {
    const authModule = getModule(AuthModule, store)
    return authModule.authenticated
  }

  public isInRole(role: string) {
    const authModule = getModule(AuthModule, store)
    return authModule.roles.some((r) => r === role)
  }

  public isInAnyRole(roles: string[]) {
    return roles.some(this.isInRole)
  }

  public displayName() {
    const authModule = getModule(AuthModule, store)
    return authModule.displayName
  }

  private redirect() {
    const authModule = getModule(AuthModule, store)
    const role = authModule.roles.length > 0 ? authModule.roles[0] : 'DEFAULT'

    const redirectUrl = router.currentRoute.params.redirectUrl
    if (redirectUrl != null) {
      router.push({ name: redirectUrl })
    } else {
      const routes = routing.loginDefaultRedirect as { [s: string]: string }
      router.push(routes[role])
    }
  }
}

export default new AuthService()
